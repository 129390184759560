import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import ContactSection from '../components/ContactSection/ContactSection';

const Sponsor = ({ data }) => {
  return (
    <Layout>
      <ContactSection block={data.sanityTexts._rawSponsor} />
    </Layout>
  );
};

export const query = graphql`
  {
    sanityTexts(_id: { eq: "texts" }) {
      _rawSponsor(resolveReferences: { maxDepth: 10 })
    }
  }
`;

export default Sponsor;
