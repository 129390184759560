import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './SplitSection.module.scss';
import classNames from 'classnames';

const SplitSection = ({ hero, left, right }) => {
  return (
    <div className={classNames(styles.split, { [styles.hero]: hero === true })}>
      <div className={classNames(styles.content, styles.left)}>
        <div className={styles.wrapper}>{left}</div>
      </div>
      <div className={classNames(styles.content, styles.right)}>
        <div className={styles.wrapper}>{right}</div>
      </div>
    </div>
  );
};

SplitSection.propTypes = {
  hero: PropTypes.bool,
  left: PropTypes.node.isRequired,
  right: PropTypes.node.isRequired,
};

SplitSection.defaultProps = {
  hero: true,
  left: null,
  right: null,
};

export default SplitSection;
