import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './ContactSection.module.scss';
import SplitSection from '../SplitSection/SplitSection';
import ContactForm from '../ContactForm/ContactForm';
import PortableText from '@sanity/block-content-to-react';

const ContactSection = ({ block, hero, askType }) => {
  return (
    <SplitSection
      hero={hero}
      left={
        <div className={styles.text}>
          <PortableText blocks={block} />
        </div>
      }
      right={<ContactForm askType={askType} />}
    />
  );
};

ContactSection.propTypes = {
  block: PropTypes.array.isRequired,
  hero: PropTypes.bool,
  askType: PropTypes.bool,
};

ContactSection.defaultProps = {
  block: null,
  hero: true,
  askType: false,
};

export default ContactSection;
